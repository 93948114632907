* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10%;
    /* border-bottom: 1px solid #000; */
    z-index: 10;

}

nav ul li {
    list-style: none;
    display: inline-block;
    margin: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    font-family: "Syne", sans-serif;

}

nav .logo {
    width: 220px;
    cursor: pointer;
}

.nav-btn {
    background: #13139c;
    color: #fff;
    font-size: 16px;
    padding: 15px 25px;
    border: 0;
    outline: 0;
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 2px 25px rgba(46, 4, 255, 0.5);
    z-index: 20;
}

.nav-btn:active{
    background: #3d3dff;
    box-shadow: 0 2px 25px rgba(255,0,130,0.2);

}

.nav-btn span{
    background: #3d3dff;
    border: 1px solid #3d3dff;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    transform: scale(0)


}

.nav-btn span:nth-child(1){
    top: 20%;
    left: 10%;
    
}

.nav-btn span:nth-child(2){
    top: 23%;
    left: 70%;
}
.nav-btn span:nth-child(3){
    top: 5%;
    left: 91%;
}
.nav-btn span:nth-child(4){
    top: -6%;
    left: 79%;
}
.nav-btn span:nth-child(5){
    top: 78%;
    left: 90%;
}
.nav-btn span:nth-child(6){
    top: 96%;
    left: 79%;
}
.nav-btn span:nth-child(7){
    top: 92%;
    left: 85%;
}
.nav-btn span:nth-child(8){
    top: 56%;
    left: 72%;
}
.nav-btn span:nth-child(9){
    top: 40%;
    left: 93%;
}
.nav-btn span:nth-child(10){
    top: 85%;
    left: 76%;
}

.nav-btn img {
    width: 14px;
    margin-left: 8px;
}

.header {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(#ededff ,#fff,#fff);
    padding: 0 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: movedown 10s linear forwards;


}

.header h1 {
    margin-top: 100px;
    font-size: 5vw;
    line-height: 7vw;
    color:#5f87ff;
    font-family: 'Syne', sans-serif;

}

.header h1 b {
    font-size: 10vw;
    font-family: 'Syne', sans-serif;
  

    background: -webkit-linear-gradient(#5f87ff 20%,#714dff 50%, #ff4cd8 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.anim{
    animation: moving 0.25s linear reverse forwards;
}

@keyframes moving{
    0%{
        transform: scale(0);
    }
    10%{
        transform: scale(1);
    }
    100%{
        top: 40%;
        left: 81%;
    }
}

.btn-container {
    margin: 20px 0;
}

.header button {
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    margin-right: 20px;
    cursor: pointer;
}

.btn-dark {
    background: #ff4cd8;
    color: #000;
    padding: 10px 10px 10px 30px;
    border-radius: 40px;
}

.btn-light {
    background: transparent;
    color: #000;
    padding: 10px 10px 10px 30px;
    border-radius: 40px;
}

.btn-dark img {
    width: 20px;
    background: #fff;
    border-radius: 50%;
    padding: 6px;
    margin-left: 30px;
}

.btn-light img {
    width: 20px;
    background: #ff4cd8;
    border-radius: 50%;
    padding: 6px;
    margin-left: 30px;
}
.header p{
    font-family: "Syne", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color:#3f3f4e;
}

.user-img{
    width: 80%;
    max-width: 500px;
    position: absolute;
    right: 13%;
    bottom: 0;
    animation: movedown 1s linear forwards;
}




@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);

    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}



/* .header p strong {
    padding: 0 3px;
    font-family: "Syne", sans-serif;
    font-size: larger;
    background: #810f9a;
    color:#fff;

} */
span{
    font-family: "Syne", sans-serif;
    font-size: 15px;

}

/* animation */
/* .header h1 span{
    background: linear-gradient(#714dff,#714dff);
    background-repeat: no-repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 0%;
} */
.header span{
    background: linear-gradient(#3f3f4e,#3f3f4e);
    background-repeat: no-repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 0%;
   
}
#l-1{
    animation: textcolor1 20s linear infinite alternate;
}
#l-2{
    animation: textcolor2 20s linear infinite alternate;
}
#l-3{
    animation: textcolor3 20s linear infinite alternate;
}
#l-4{
    animation: textcolor4 20s linear infinite alternate;
}

/* .header h1 span:{
    animation: textcolor2 6s linear infinite alternate;
} */
@keyframes textcolor1 {
    0%{
        background-size: 0%;
    }
    10%{
        background-size: 50%;
    }
    25%{
        background-size: 100%;
    }
    50%{
        background-size: 100%;
    }
    
    75%{
        background-size: 100%;
    }
    100%{
        background-size: 100%;
    }
    
}
@keyframes textcolor2 {
    0%{
        background-size: 0%;
    }
    25%{
        background-size: 0%;
    }
    50%{
        background-size: 100%;
    }
    
    75%{
        background-size: 100%;
    }
    100%{
        background-size: 100%;
    }
    
}
@keyframes textcolor3 {
    0%{
        background-size: 0%;
    }
    25%{
        background-size: 0%;
    }
    50%{
        background-size: 0%;
    }
    
    75%{
        background-size: 100%;
    }
    100%{
        background-size: 100%;
    }
    
}
@keyframes textcolor4 {
    0%{
        background-size: 0%;
    }
    25%{
        background-size: 0%;
    }
    50%{
        background-size: 0%;
    }
    
    75%{
        background-size: 0%;
    }
    100%{
        background-size: 100%;
    }
    
}

.menu-icon {
    display: none; 
}
.show-menu{
    max-height: 100px;
}
.header h2{
    display: block;
    color: #bbb;
    font-size:50px;
}


#l-1,#l-2,#l-3,#l-4 {
    font-weight:15px !important;
}

/* Media Queries for navbar */

@media only screen and (max-width: 600px){
    nav ul{
        position: absolute;
        width: 100%;
        background: #666aff;
        color: #fff;
        left:0;
        top: 100%;
        text-align: center;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
    }
    .nav-btn{
        display:none;
    }

    nav ul li{
        margin: 10px;
        font-size: 14px;
    }
    .menu-icon {
        
        display: block; 
        margin-left: auto;
        width: 30px;
        
    }
    .header h2{
        font-size:smaller;
        display: block;
        color: #8372a4;
    }
    
}

/* Media Queries for header content */

@media only screen and (max-width: 600px){
.header{
    min-height: auto;
    padding:0;
}
.user-img{
width:100%;
right: auto;
position: relative;
margin-top: 100px;

}
.header-content{
    position: absolute;
    bottom: 0;
    padding: 0 10%;
    z-index:2;
  
    padding-bottom: 40px;
    background: linear-gradient(#ffffffb0,#fff,#ffffffb3,transparent)
}
.btn-container{
    margin: 5px 0;
}
.header button{
    font-size: 12px;
    
}

}

